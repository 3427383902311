export default {
    install(Vue, defaultServerFromLocation, onloaded) {
        var config = Vue.observable(require('@/assets/config.json'));
        Vue.set(config, "loaded", false);
        const getRuntimeConfig = () => {
            return fetch('./config.json?ms=' + Date.now()).then((res) => res.json()).catch(err => {
                console.error("Failed to get config:", err);
                return {};
            });
        }

        config.promise = getRuntimeConfig().then((json) => {
            // Reactively use all the config values
            for (const key of Object.keys(json)) {
                Vue.set(config, key, json[key]);
            }
            // If default server is not set, default to current server address
            if (!json.defaultBaseUrl) {
                if (json.defaultServer) {
                    // TODO - Only to migrate old values (defaultServer was renamed defaultBaseUrl), can be removed later...
                    Vue.set(config, "defaultBaseUrl", defaultServerFromLocation);
                } else {
                    Vue.set(config, "defaultBaseUrl", json.defaultServer);
                }
            }
            if (json.useFullyQualifiedDMLinks == undefined) {
                Vue.set(config, "useFullyQualifiedDMLinks", true); // Default to true
            }
            Vue.set(config, "loaded", true);

            // Tell callback we are done loading runtime config
            if (onloaded) {
                onloaded(config);
            }
            return config;
        });

        /**
         * If there is an explicit mapping for this MX domain in the config file, return the endpoint URL that it maps to.
         * @param {*} domain 
         * @returns 
         */
        config.getMatrixDomainPartMapping = (domain) => {
            console.log("Get domain endpoint mapping for", domain);
            if (config.matrixDomainPartMapping && config.matrixDomainPartMapping[domain]) {
                const mapping = config.matrixDomainPartMapping[domain];
                if (Array.isArray(mapping)) {
                    return mapping[0]; //TODO - Use the first one for now, but maybe rotate somehow?
                }
                return mapping;
            }
            return undefined;
        }

        Vue.prototype.$config = config;
    }
}
