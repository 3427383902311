<template>
  <v-row
    class="action-row ma-0 pa-0"
    no-gutters
    align-content="center"
    v-on="$listeners"
    v-show="icon === 'notifications_active' ? this.windowNotificationPermission() !== 'granted' : true"
  >
    <v-col cols="auto" class="me-2">
      <v-icon :size="iconSize">{{ icon }}</v-icon>
    </v-col>
    <v-col>{{ text }}</v-col>
  </v-row>
</template>

<script>
import { windowNotificationPermission } from "../plugins/notificationAndServiceWorker.js"

export default {
  name: "ActionRow",
  props: {
    icon: {
      type: String,
      default: function () {
        return null;
      },
    },
    iconSize: {
      type: Number,
      default: function() {
        return 22;
      }
    },
    text: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  methods: {
    windowNotificationPermission
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>