export function registerServiceWorker() {
  if("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js");
  } else {
    console.log("No Service Worker support!");
  }
}

export function requestNotificationPermission() {
  if("PushManager" in window) {
    window.Notification.requestPermission();
  } else {
    console.log("No Push API Support!");
  }
}

export function windowNotificationPermission() {
  return window.Notification.permission
}

export function notificationCount() {
  return this.$matrix.notificationCount
}
