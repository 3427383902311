<template>
    <v-responsive v-if="item.event.getContent().msgtype == 'm.video'" :class="{'thumbnail-item': true, 'preview': previewOnly}"
        @click.stop="$emit('itemclick', {item: item})">
        <video :src="item.src"  :controls="!previewOnly" class="w-100 h-100">
            {{ $t('fallbacks.video_file') }}
        </video>
    </v-responsive>
    <v-img v-else-if="item.event.getContent().msgtype == 'm.image'" :aspect-ratio="previewOnly ? (16 / 9) : undefined" :class="{'thumbnail-item': true, 'preview': previewOnly}" :src="item.src" :contain="!previewOnly" :cover="previewOnly"
        @click.stop="$emit('itemclick', {item: item})" />
    <div v-else :class="{'thumbnail-item': true, 'preview': previewOnly, 'file-item': true}" @click.stop="$emit('itemclick', {item: item})">
        <v-icon>description</v-icon>
        {{ $sanitize(item.event.getContent().body) }}
    </div>
</template>
<script>

export default {
    props: {
        /**
         * Item is an object of { event: MXEvent, src: URL } 
         */
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        previewOnly: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
    },
}
</script>


<style lang="scss">
@import "@/assets/css/chat.scss";

.thumbnail-item {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.file-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    flex-direction: column;
    padding: 20px;
    .v-icon {
        margin-bottom: 10px;
        color: currentColor;
    }
}
</style>